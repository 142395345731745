.contact {
  font-family: "Poppins", sans-serif;
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 6vh 0;
}

.contact-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.contact-social {
  width: 50%;
  display: flex;
  justify-content: space-between;
}

.contact-social_icon {
  fill: var(--second-color);
  cursor: pointer;
  width: 32px;
  margin-top: 3vh;
}

.contact-social_icon:hover {
  fill: var(--third-color);
}

.contact-headline {
  margin: 0 auto;
  width: 100%;
  position: relative;
  font-size: 1.625rem;
  top: 10%;
  font-weight: 700;
  color: var(--third-color);
  text-transform: uppercase;
  letter-spacing: 3px;
  cursor: default;
}

.contact-title {
  width: 100%;
  margin-top: 3vh;
}

.contact-title_header {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--third-color);
  text-transform: uppercase;
  margin-bottom: 2vh;
}

.header_response {
  position: relative;
  top: 100px;
}

.contact-title_message {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 2px;
  color: var(--second-color);
  margin-bottom: 0.5rem;
}

.contact-form {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.contact-form_name,
.contact-form_email,
.contact-form_message {
  margin-bottom: 1rem;
  border: 2px solid var(--second-color);
  padding: 0.5rem;
}

.contact-form_message {
  height: 10vh;
  resize: none;
}

.contact-form_name:focus,
.contact-form_email:focus,
.contact-form_message:focus {
  outline: none;
  border: 2px solid var(--third-color);
  box-shadow: 0 0 10px var(--third-color);
}

.contact-form_button {
  text-transform: uppercase;
  font-weight: 700;
  padding: 1rem 0;
  letter-spacing: 0.5rem;
  cursor: pointer;
  border: 2px solid var(--second-color);
  color: var(--second-color);
  background-color: rgb(0, 0, 0, 0);
  position: relative;
  width: 100%;
  left: 100%;
  transform: translateX(-100%);
}

.contact-form_button:hover {
  background-color: var(--third-color);
  color: var(--main-color);
  border: 2px solid var(--third-color);
}

@media (min-width: 576px) {
  .contact-headline {
    font-size: 2.25rem;
  }

  .contact-title_header {
    font-size: 1.625rem;
  }

  .contact-title_message {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }

  .contact-form_name,
  .contact-form_email,
  .contact-form_message {
    font-size: 1.25rem;
  }

  .contact-form_button {
    max-width: 150px;
  }
}

@media (min-width: 768px) {
  .contact-headline {
    max-width: 700px;
  }

  .contact-container {
    max-width: 700px;
  }

  .contact-social_icon {
    width: 36px;
  }
}

@media (min-width: 992px) {
  .contact-headline {
    position: absolute;
    bottom: 0;
    top: auto;
    right: 0;
    left: 0;
    text-align: right;
    font-size: 5rem;
    max-width: 850px;
    opacity: 0.5;
  }

  .contact-container {
    max-width: 850px;
    margin-bottom: 10rem;
  }

  .contact-title_header {
    font-size: 2rem;
  }

  .contact-title_message {
    font-size: 1.625rem;
  }

  .contact-form_name,
  .contact-form_email,
  .contact-form_message {
    font-size: 1.2rem;
    margin: 2vh 0;
  }

  .contact-form_button {
    font-size: 1.2rem;
    max-width: 200px;
  }
}

@media (min-width: 992px) {
  .contact-headline {
    font-size: 5.5rem;
  }

  .contact-title_header {
    font-size: 2.5rem;
  }

  .contact-title_message {
    font-size: 2rem;
  }

  .contact-form_name,
  .contact-form_email,
  .contact-form_message {
    font-size: 1.5rem;
    margin: 2vh 0;
  }

  .contact-form_button {
    font-size: 1.5rem;
    max-width: 250px;
  }

  .contact-social_icon {
    width: 40px;
  }
}
