:root {
  --main-color: rgb(255, 255, 255);
  --second-color: rgb(0, 0, 0);
  --third-color: #4f8d60;
  --fourth-color: rgb(153, 153, 153);
  --fifth-color: rgb(216, 216, 216);
}

* {
  scroll-behavior: smooth;
}

body {
  background: var(--main-color);
  min-width: 370px;
}

html {
  font-family: "Poppins", sans-serif;
}

/* 
Change Scrollbar Style
*/

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--main-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--fifth-color);
  border: 1px solid var(--fifth-color);
  border-radius: 20px;
  height: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--second-color);
  border: 1px solid var(--second-color);
}
