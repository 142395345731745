.home {
  background: var(--main-color);
  font-family: "Poppins";
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.home-language {
  position: absolute;
  top: 1rem;
  right: 1rem;
  animation: fadeUp;
  animation-duration: 1.5s;
}

.home-language-btn {
  border: none;
  cursor: pointer;
  margin: 0 1rem;
  padding: 0.5rem;
  padding-bottom: 0;
  color: var(--second-color);
  background-color: rgb(0, 0, 0, 0);
}

.home-language-btn:hover {
  color: var(--third-color);
}

.home-language-btn.active-language {
  border-bottom: 2px solid var(--second-color);
}

.home-language-btn.active-language:hover {
  border-bottom: 2px solid var(--third-color);
}

.home-content {
  width: 90%;
  min-width: 370px;
  text-align: center;
  cursor: default;
}

.home-content h1 {
  font-size: 2rem;
  letter-spacing: 2px;
  font-weight: 500;
  animation: fadeDown;
  animation-duration: 1.5s;
  margin-bottom: 2rem;
  animation-fill-mode: forwards;
}

.home-content h2 {
  font-size: 1.625rem;
  letter-spacing: 2px;
  font-weight: 500;
  animation: fadeDown;
  animation-duration: 1.5s;
  animation-delay: 0.5s;
  margin: 2rem 0 6rem;
  animation-fill-mode: forwards;
  opacity: 0;
}

.home-content a {
  border: 2px solid var(--second-color);
  background-color: rgb(0, 0, 0, 0);
  padding: 1rem;
  font-size: 0.75rem;
  text-decoration: none;
  color: var(--second-color);
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  animation: fadeDown;
  animation-duration: 1.5s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

.home-content a:hover {
  border: 2px solid var(--third-color);
  color: var(--third-color);
}

.home-social {
  position: fixed;
  left: 1vw;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  visibility: hidden;
}

.home-social::after {
  content: "";
  width: 2px;
  background-color: var(--main-color);
  animation: growUp 2.5s;
  animation-fill-mode: forwards;
}

.home-social a {
  color: var(--second-color);
  margin-bottom: 1vh;
  cursor: pointer;
}

.home-social a:hover {
  color: var(--third-color);
}

@media (min-width: 576px) {
  .home-content h1 {
    font-size: 2.75rem;
  }
  .home-content h2 {
    font-size: 2rem;
  }
  .home-content a {
    font-size: 1rem;
  }
}

@media (min-width: 768px) {
  .home-content h1 {
    font-size: 3.25rem;
    letter-spacing: 0.5rem;
    margin-bottom: 3rem;
  }
  .home-content h2 {
    font-size: 2.5rem;
  }
}

@media (min-width: 992px) {
  .home-social {
    visibility: visible;
  }

  .home-content h1 {
    font-size: 3.5rem;
    letter-spacing: 0.5rem;
    margin-bottom: 3rem;
  }
  .home-content h2 {
    font-size: 3rem;
  }
  .home-content a {
    font-size: 1.25rem;
  }
}

@media (min-width: 1200px) {
  .home-social {
    visibility: visible;
  }

  .home-content h1 {
    font-size: 3.75rem;
  }
  .home-content h2 {
    font-size: 3.25rem;
  }
  .home-content a {
    font-size: 1.5rem;
  }
}

@keyframes fadeUp {
  from {
    transform: translateY(-25px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeDown {
  from {
    transform: translateY(45px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes growUp {
  0% {
    height: 0;
    background-color: var(--second-color);
  }

  100% {
    height: 45vh;
    background-color: var(--second-color);
  }
}
