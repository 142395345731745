.navbar {
  background: var(--main-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 20;
  height: 5vh;
  border-bottom: 1px solid var(--fifth-color);
}

.navbar-bottom {
  position: absolute;
  bottom: 0;
}

.navbar-top {
  position: fixed;
  top: 0;
}

.navbar-container {
  height: 100%;
  margin-left: 1rem;
  width: 40px;
}

.navbar-logo {
  stroke: var(--second-color);
  width: 100%;
  height: 100%;
}

.navbar-logo:hover {
  stroke: var(--third-color);
}

.navbar-horizontal-menu {
  display: none;
  padding: 0 3vw;
}

.navbar-horizontal-menu li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.navbar-horizontal-menu a {
  display: block;
  padding: 1vh 3vw;
  margin: 0 2vw;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.2rem;
  text-decoration: none;
  color: var(--second-color);
  position: relative;
}

.navbar-horizontal-menu a:hover {
  color: var(--third-color);
}

.navbar-horizontal-menu .active::after {
  content: "";
  display: block;
  height: 3px;
  background-color: var(--second-color);
  position: absolute;
  left: 3vh;
  right: 3vh;
  bottom: 0.1vh;
}

.active:hover::after {
  background-color: var(--third-color);
}

.navbar-hamburger-toggleBtn {
  cursor: pointer;
  padding: 0 10px 0 50px;
}

.navbar-hamburger-toggleBtn:hover {
  color: var(--third-color);
}

.hamburger-navigation-menu {
  background: var(--third-color);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.hamburger-navigation-menu-btn {
  color: var(--second-color);
  cursor: pointer;
  position: absolute;
  top: 1px;
  right: 10px;
}

.hamburger-navigation-menu-btn:hover {
  color: var(--main-color);
}

.hamburger-navigation-menu-list {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.hamburger-navigation-menu-list li {
  width: 100%;
}

.hamburger-navigation-menu-list li a {
  padding: 3rem 0;
  text-align: center;
  display: inline-block;
  width: 100%;
  text-decoration: none;
  font-size: 2rem;
  font-weight: 700;
  color: var(--second-color);
}

.hamburger-navigation-menu-list li a:hover {
  color: var(--main-color);
}

@media (min-width: 992px) {
  .navbar-horizontal-menu {
    display: flex;
  }
  .navbar-hamburger-menu {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar-horizontal-menu a {
    font-size: 1.5rem;
  }
}
