.portfolio {
  width: 100%;
  margin: 10vh 0;
  position: relative;
  font-family: "poppins";
}

.portfolio-header {
  display: flex;
  align-items: baseline;
  margin-bottom: 1vh;
}

.portfolio-header-number {
  font-size: 1.625rem;
  font-weight: 700;
  color: var(--third-color);
}

.portfolio-header-title {
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 0.2rem;
  opacity: 0.8;
  text-transform: uppercase;
  margin-left: 1rem;
}

.portfolio-title {
  margin: 2vh 0 3vh;
}

.portfolio-title-text {
  color: var(--third-color);
  font-size: 1.25rem;
  letter-spacing: 0.1rem;
}

.portfolio-container {
  width: 100%;
  max-width: 900px;
  margin: auto;
  border: 1px solid var(--fourth-color);
}

.portfolio-img-pic {
  width: 100%;
  height: 100%;
}

.portfolio-content {
  background: var(--second-color);
  opacity: 0.9;
  padding: 1rem;
  position: relative;
}

.portfolio-content-description p {
  font-size: 1rem;
  letter-spacing: 2px;
  line-height: 1.25rem;
  color: var(--main-color);
}

.portfolio-content-technologies {
  display: flex;
  flex-wrap: wrap;
  color: var(--main-color);
  margin-top: 1rem;
}

.portfolio-content-technologies li {
  font-size: 1rem;
  margin-right: 1rem;
  line-height: 1.25rem;
}

.portfolio-content_links {
  margin-top: 1rem;
}

.portfolio-content_icon {
  color: var(--second-color);
  font-size: 1rem;
  margin-right: 2rem;
  border-radius: 100%;
  background-color: var(--main-color);
  width: 24px;
  height: 24px;
  border: 1px solid var(--main-color);
}

.portfolio-content_icon:hover {
  animation: linksAnimation 0.2s;
  animation-fill-mode: forwards;
}

@media (min-width: 576px) {
  .portfolio-header-number {
    font-size: 2rem;
  }

  .portfolio-header-title {
    font-size: 1.625rem;
    font-weight: 700;
    letter-spacing: 0.2rem;
    margin-left: 1rem;
  }

  .portfolio-title {
    margin: 2vh 0 3vh;
  }

  .portfolio-title-text {
    font-size: 1.5rem;
    letter-spacing: 0.1rem;
  }

  .portfolio-content {
    padding: 1rem;
  }

  .portfolio-content_icon {
    color: var(--second-color);
    font-size: 1rem;
    margin-right: 2rem;
    width: 28px;
    height: 28px;
  }
}

@media (min-width: 768px) {
  .portfolio-container {
    position: relative;
    overflow: hidden;
    box-shadow: 5px 20px 40px var(--fourth-color);
  }

  .portfolio-container:hover .portfolio-content {
    visibility: visible;
    top: 0;
  }

  .portfolio-img-pic {
    width: 100%;
    height: 100%;
  }

  .portfolio-content {
    background: var(--second-color);
    opacity: 0.9;
    padding: 1rem;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: 0.5s ease-in-out;
    visibility: hidden;
    overflow: hidden;
  }

  .portfolio-content-description p {
    font-size: 1.25rem;
    line-height: 2rem;
  }

  .portfolio-content-technologies {
    margin-top: 2rem;
  }

  .portfolio-content-technologies li {
    font-size: 1.25rem;
    margin-right: 1.5rem;
    line-height: 2rem;
  }

  .portfolio-content_links {
    margin-top: 5rem;
  }

  .portfolio-content_icon {
    margin-right: 2rem;
    height: 30px;
    width: 30px;
  }
}

@media (min-width: 992px) {
  .portfolio-container {
    width: 90%;
  }
}

@media (min-width: 1200px) {
  .portfolio {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 43vw;
    max-width: 800px;
  }

  .portfolio-container {
    width: 100%;
    height: 360px;
  }
}

@keyframes linksAnimation {
  0% {
    box-shadow: 1px 0 6px 1px var(--main-color);
  }

  25% {
    box-shadow: 0 1px 6px 1px var(--main-color);
  }

  50% {
    box-shadow: -1px 0 6px 1px var(--main-color);
  }

  75% {
    box-shadow: 0 -1px 6px 1px var(--main-color);
  }

  100% {
    box-shadow: 1px 1px 8px 1px var(--main-color);
  }
}
